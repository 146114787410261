import { z } from "zod";

export const BrandCodeSchema = z.enum(["gac", "gaa", "wb", "qor", "pp"]);

export type BRAND_CODE = z.infer<typeof BrandCodeSchema>;

export const PaintMixInputSchema = z.object({
  colorName: z.string(),
  hex: z.string(),
  part1: z.string().nullish(),
  part2: z.string().nullish(),
  part3: z.string().nullish(),
  part4: z.string().nullish(),
  tube1: z.string().nullish(),
  tube2: z.string().nullish(),
  tube3: z.string().nullish(),
  tube4: z.string().nullish(),
  brandCode: BrandCodeSchema,
});

export type PaintMixInputType = z.infer<typeof PaintMixInputSchema>;

export const PaintMixOutputSchema = z.object({
  colorName: z.string(),
  id: z.string(),
  hex: z.string(),
  brandCode: BrandCodeSchema,
  part1: z.string().optional(),
  part2: z.string().optional(),
  part3: z.string().optional(),
  part4: z.string().optional(),
  tube1: z.string().optional(),
  tube2: z.string().optional(),
  tube3: z.string().optional(),
  tube4: z.string().optional(),
  mixFullPublicId: z.string().optional(),
  mixPublicName: z.string().optional(),
});

export type PaintMixOutputType = z.infer<typeof PaintMixOutputSchema>;

export const PaintSpecSchema = z.object({
  brandCode: BrandCodeSchema,
  paintId: z.string(),
  paintName: z.string(),
  hex: z.string(),
});

export type PaintSpecType = z.infer<typeof PaintSpecSchema>;

export const SharePaintMixInputSchema = z.object({
  mixPublicName: z.string(),
  userPublicName: z.string().optional(),
  setUserPublicName: z.boolean(),

  paintpart1: z.optional(PaintSpecSchema),
  paintpart2: z.optional(PaintSpecSchema),
  paintpart3: z.optional(PaintSpecSchema),
  paintpart4: z.optional(PaintSpecSchema),
});

export type SharePaintMixInputType = z.infer<typeof SharePaintMixInputSchema>;

export const PostShareMixInputSchema = z.object({
  json: z.string().transform((data) => {
    try {
      return SharePaintMixInputSchema.parse(JSON.parse(data));
    } catch (e) {
      throw new Error("Invalid JSON structure");
    }
  }),
  image: z.any().refine((file) => file instanceof Blob, { message: "Invalid file format" }),
});
